<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t('solution60')}}</h1>
          <h2>{{$t('solutionDns')}}</h2>
          <p class="sm">{{$t('solution61')}}</p>
          <el-button type="primary">{{$t('getPlan')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution62')}}</h2>
        <ul class="product-list child">
          <li>
            <div class="img"><img src="@/assets/images/image22.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution63')}}</h4>
              <p>{{$t('solution64')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image23.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution65')}}</h4>
              <p>{{$t('solution66')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image24.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution67')}}</h4>
              <p>{{$t('solution68')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image19.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution69')}}</h4>
              <p>{{$t('solution70')}}</p>
            </div>
          </li>
        </ul>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionDns",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('solutionDns')+'-'+this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
